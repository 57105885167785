import React, { useContext, useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Layout, Button, Badge, Menu, Dropdown } from 'antd';
import { AuthContext } from 'authContext';
import { BellIcon } from 'components/svgs';
import { Link, useHistory } from 'react-router-dom';
import { colors, formatRole } from 'utilities';

import TribTechLogo  from '../../images/TribTechLogo.png';
 import { ReactComponent as MenuIcon } from 'images/MenuIcon.svg';
 
import { ReactComponent as ProfileIcon } from 'images/user.svg';
import { LogShift } from 'components';
import { me, USER_LOGOUT, GET_USER_NOTIFICATIONS } from 'services';
import styled from '@emotion/styled/macro';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const { Item } = Menu;

const HeaderItemWrapper = styled.div`
  height: 50%;
  font-weight: bold;
`;
const UserLog = styled.div`
  position:absolute;

`;
const DropDownMenu = styled.div`
  display: flex;
  // width: 90px;
  flex-direction: column;
  // right:0px;
  border-radius: 5px;
  padding: 5px;
  // justify-content: space-between;
  background-color: #e7e7e7;
  // align-items: flex-start;
  height: fit-content;
  z-index: 3;
  align-items: center;
  // position: inherit;
  border-radius:6px
`;
const DropMenuHolder = styled.div`
display:flex ; 
flex-direction:column;
height: inherit;
gap: 40px;
margin-left: 10px;
background-color: #f2f2f2;
text-align: center;
align-items:center;
`
const ItemWrapper = styled.span`
  color: red;
`;

const TimeAgo = styled.p`
  color: #979797;
  font-size: 12px;
  float: left;
  font-weight: lighter;
`;

const NotificationContent = styled.p`
  max-width: 270px;
  word-wrap: break-word;
  white-space: break-spaces;
  font-weight: lighter;
  font-size: 13px;
  text-align: start;
`;

const NotificationTitle = styled.strong`
  display: block;
  word-wrap: break-word;
  white-space: break-spaces;
  font-size: 13px;
`;

const Notify = styled.div`
  display: flex;
  width: 95%;
  flex-direction: row;
  justify-content: space-between;
`;

const NotificationWrapper = styled.div`
display:flex;
width: 25%;
ul {
    display: flex;
    margin: 0px;
    margin-right:auto;
    line-height: 45px;
    list-style: none;
    padding-inline-start: 0;
    li + li {
      margin-inline-start: 100px;
      @media screen and (max-width: 1025px) {
        margin-inline-start: 0;
      }
    }
    
    a {
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      text-decoration: none;
    }
    
    button {
    margin: 0px;
    }
  `;

  const EnglishLogo = styled.img`
    width: 200px;
  `;
const { Header } = Layout;
const AppHeader = ({ onClick }) => {
  const { t, i18n } = useTranslation();
  document.body.dir = i18n.language=='ar'?'rtl':'ltr';
  const changeLanguage = (lng) => {
    setTimeout(()=>{
      i18n.changeLanguage(lng);
      document.body.dir = i18n.language=='ar'?'rtl':'ltr';
    }, 12)
    window.location.reload(false);
  };
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const logout = authContext && authContext.logout;
  const [userLogout] = useMutation(USER_LOGOUT);
  const [dropDownState, setDropDownState] = useState(false);
  const [page, setPage] = useState(2);
  const [count, setCount] = useState(5);
  const [notification, setNotification] = useState([]);
  const [billColor, setBillColor] = useState('#1890ff');
  const handleLogout = async () => {
    await userLogout();
    logout();
    history.push('/login');
  };

  const { data: userData } = useQuery(me, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: notificationsData,
    fetchMore: fetchMoreNotifications,
  } = useQuery(GET_USER_NOTIFICATIONS, {
    variables: {
      page: 1,
    },
  });

  useEffect(() => {
    if (notificationsData?.getUserNotifications?.data)
      setNotification(notificationsData?.getUserNotifications?.data);
  }, [count, notificationsData]);

  useEffect(() => {
    if (i18n.language !== 'ar' && i18n.language !== 'en') {
      changeLanguage('en');
    }
  }, []);

  const NotificationsMenu = () => {
    return (
      <Menu>
        {notification &&
          notification.length > 0 &&
          notification.map((notification) => (
            <Item
              key={notification.id}
              onClick={() => {
                history.push('/trip-private');
              }}
            >
              <Notify>
                <NotificationTitle>
                  {notification.notification.title_en}
                </NotificationTitle>
                <TimeAgo>
                  {moment(notification.created_at).locale('en-SG').fromNow()}
                </TimeAgo>
              </Notify>
              <NotificationContent>
                {notification.notification.message_en}
              </NotificationContent>
            </Item>
          ))}
        {notification.length == 0 && (
          <Item key="*">
            <Notify>
              <NotificationTitle>Notifications</NotificationTitle>
              <TimeAgo>Now</TimeAgo>
            </Notify>
            <NotificationContent>
              No new notifications found!
            </NotificationContent>
          </Item>
        )}
      </Menu>
    );
  };

  setTimeout(() => {
    fetchMoreNotifications({
      variables: {
        page: page,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        return fetchMoreResult;
      },
    }).then((res) => {
      let paginatorInfo = res?.data?.getUserNotifications?.paginatorInfo;
      if (paginatorInfo.total != count) {
        setCount(count + paginatorInfo?.count);
        if (res?.data?.getUserNotifications?.data.length > 0) {
          if (paginatorInfo.hasMorePages == true) {
            setPage(page + 1);
          }
          setNotification([
            ...res?.data?.getUserNotifications?.data,
            ...notification,
          ]);
          setBillColor('#d32a2a');
        }
      }
    });
  }, 9000000);

  const {
    location: { pathname },
  } = history;

  const isExist = (value) => {
    return value !== undefined && value !== null;
  };
  return (
    <div>
      <Header
        style={{
          height: 80,
          display: 'flex',
          lineHeight: 'normal',
          padding: '5px',
          alignItems: 'center',
          justifyContent: 'space-between',
          background: colors.secondaryColor,
          minWidth: 'fit-content',
        }}
      >
        {isMobile ? (
          <Button
            style={{
              background: colors.secondaryColor,
              border: 0,
            }}
            onClick={onClick}
          >
            <MenuIcon />
          </Button>
        ) : (
          <></>
        )}
        <div
          style={{
            flex: '1, 1, 0',
          }}
        >
          <Link to="/">
            {i18n.language === 'en' ? (
              <EnglishLogo
                src={TribTechLogo}
              />
            ) : (
              <EnglishLogo
                src={TribTechLogo}
              />
            )}
          </Link>
        </div>

        {i18n.language === 'en' ? (
          <button
            style={{
              border: 'none',
              cursor: 'pointer',
            }}
            onClick={() => {
              changeLanguage('ar');
            }}
          >
            <img
              style={{ width: '33px', height: '33px' }}
              src={require('../../../src/images/download.png')}
              alt="Ar"
            ></img>
          </button>
        ) : (
          <button
            style={{ border: 'none', cursor: 'pointer' }}
            onClick={() => {
              changeLanguage('en');
            }}
          >
            <img
              style={{ width: '33px', height: '33px' }}
              src={require('../../../src/images/english.e7a8cee7.png')}
              alt="En"
            ></img>
          </button>
        )}
        {isMobile ? <></> : <LogShift />}
        <NotificationWrapper>
          <ul>
            <li>
              <Dropdown
                overlay={NotificationsMenu()}
                placement="bottomCenter"
                overlayClassName="notifications-menu"
                onVisibleChange={() => {
                  setBillColor('#1890ff');
                }}
              >
                <a className="ant-dropdown-link" href="#">
                  <BellIcon color={billColor} />
                  {t('Notifications')}
                </a>
              </Dropdown>
            </li>
          </ul>
        </NotificationWrapper>
        <DropMenuHolder>
          <Button
            style={{
              background: colors.secondaryColor,
              border: 0,
              margin: '0px 8px',
              width: '80px',
              top: '6px',
              transform: 'translate(4px, 22px)',
            }}
            onClick={() => setDropDownState(!dropDownState)}
          >
            <ProfileIcon />
          </Button>
          {dropDownState ? (
            <DropDownMenu>
              {authContext?.user?.name && (
                <Badge
                  status="success"
                  text={authContext?.user?.name}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                />
              )}
              {userData?.me?.role?.name && (
                <HeaderItemWrapper
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {t('Role')}: {formatRole(userData?.me?.role?.name)}
                </HeaderItemWrapper>
              )}
              {isExist(userData?.me?.partner_segment?.credit_balance) && (
                <HeaderItemWrapper>
                  Remaining Credit:
                  <ItemWrapper>
                    {userData.me.partner_segment.credit_balance} LE
                  </ItemWrapper>
                </HeaderItemWrapper>
              )}
              <LogShift />
              <Button
                type="link"
                onClick={() => handleLogout()}
                style={{ height: '50%', padding: 0, marginTop: '10px' }}
              >
                {t('Logout')}
              </Button>
            </DropDownMenu>
          ) : (
            <></>
          )}
        </DropMenuHolder>
      </Header>
    </div>
  );
};

export default AppHeader;
